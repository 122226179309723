const authKeys = {
    'deepl': {
        auth_key: '131f0200-65ea-0616-678e-01b0126d00b3:fx'
    },
    'fb' : {
        apiKey: 'AIzaSyCf4FjZ26qvnENy3IOVo-KTEM_LJAiM6rI',
        messagingSenderId: '115328737235',
        appId: '1:115328737235:web:f8d87c664ec7f1e9f19926',
        measurementId: 'G-DLEMFELYBK',
        storageBucket: 'szenodb'
    }
}

export { authKeys }